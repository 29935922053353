
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import LazyImage from "@src/components/LazyImage";
import BorderBox from "@src/components/BorderBox";
import ListBox from "@src/components/ListBox";
import GoogleMap from "@src/components/GoogleMap";
import InstaImage from "@src/components/InstaImage";
import { murakamiMasanori } from "@src/constants/authors";
import {
  harajukuRyunokoRyuunokoPaymentImage,
  harajukuRyunokoRyuunokoTopImage,
  harajukuRyunokoRyuunokoWaitingImage,
} from "@src/constants/images";
export const meta = {
  path: "/media/harajuku/ryunoko",
  title: "【行列情報あり】原宿の中華「龍の子」で本場の四川麻婆豆腐を食べよう！",
  description: "原宿の中華(四川料理)の人気店「龍の子」の人気メニュー、待ち時間、支払い方法、アクセスなどについてまとめました！ディナーだとややお値段がかかるお店のお得なランチメニュー情報、人気店で待ち時間が長いので、実際にお店に訪問した方の行列情報をご共有させていただければと思います！",
  topImg: harajukuRyunokoRyuunokoTopImage,
  author: murakamiMasanori,
  tags: ["中華", "原宿", "人気店", "お店の紹介", "女子会"]
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Text = makeShortcode("Text");
const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`原宿の中華(四川料理)の人気店「龍の子」についてご紹介します！`}</p>
    <p>{`「人気メニュー」「待ち時間」「支払い方法」「アクセス」など、お店に行く上で気になる情報を、`}<strong parentName="p">{`実際に訪問した方の声`}</strong>{`も交えて掲載しています！`}</p>
    <p>{`ディナーだとややお値段がかかるお店のお得なランチメニュー情報や、人気店で待ち時間が長いからこそ、実際`}<strong parentName="p">{`リアルな行列情報`}</strong>{`をご共有させていただければと思います！`}</p>
    <h2>{`四川料理「龍の子」とは？`}</h2>
    <InstaImage postId="B572d5tn5Ll" mdxType="InstaImage" />
    <p>{`1977 年創業の老舗四川料理店で、`}<strong parentName="p">{`食べログ評価：3.75(2020 年 5 月時点)`}</strong>{`と高い原宿の人気の行列店です。
旨辛の本格四川料理が食べられるお店として有名で、特に麻婆豆腐が大人気のメニューになっています！`}</p>
    <h3>{`そもそも四川料理って何？`}</h3>
    <p>{`麻婆豆腐、担々麺などの発祥の土地が四川で、山椒・唐辛子をふんだんに使ったピリッとした刺激的な料理のことを四川料理と呼んでいるそうです。`}</p>
    <hr></hr>
    <h2>{`「龍の子」の人気メニュー２選`}</h2>
    <h3>{`麻婆豆腐(税込：1,980 円)`}</h3>
    <p><strong parentName="p">{`ランチメニュー：税込 1,320 円`}</strong></p>
    <InstaImage postId="B99TpyihCom" mdxType="InstaImage" />
    <p>{`「龍の子」で 1 番人気のメニューが、この麻婆豆腐。
ランチメニューだと、`}<strong parentName="p">{`ライス、スープ、お新香`}</strong>{`がついて、なんと通常メニューよりかなりお得で食べられます！`}</p>
    <h4>{`ガッツリ食べたい方は大盛りを！`}</h4>
    <p><strong parentName="p">{`+200 円`}</strong>{`で麻婆豆腐を大盛りに変更できます！
ガッツリ麻婆豆腐が食べたい方はコチラがオススメです。`}</p>
    <h4>{`麻婆豆腐は辛さと痺れが調整できる`}</h4>
    <p>{`四川から調達した調味料で作る本格的な`}<strong parentName="p">{`辛さと痺れ`}</strong>{`が楽しめますが、人ぞれぞれ求めるものは異なります！
「龍の子」では、麻婆豆腐の辛さ・痺れが調整できるようになてちるので、相談してお好みの味で麻婆豆腐を食べてみてください！`}</p>
    <hr></hr>
    <h3>{`坦々麺(税込：1,430 円)`}</h3>
    <p><strong parentName="p">{`ランチメニュー：税込 1,320 円`}</strong></p>
    <InstaImage postId="B-EKcUzDE3y" mdxType="InstaImage" />
    <p>{`麻婆豆腐が一番の人気ですが、こちらの坦々麺も麻婆豆腐に次いで人気のメニューになります。`}</p>
    <p>{`本場四川の調味料で作る`}<strong parentName="p">{`白ごまの風味が効いたまろやかの濃厚スープ`}</strong>{`の坦々麺。
ランチメニューだと、先ほどの麻婆豆腐ランチと一緒で、`}<strong parentName="p">{`ライス、スープ、お新香`}</strong>{`がついたお得な値段！`}</p>
    <h4>{`ガッツリ食べたい方は麺の大盛りを！`}</h4>
    <p><strong parentName="p">{`+100 円`}</strong>{`で麺の大盛りができるので、ガッツリ坦々麺が食べたい方は、大盛りで食べてみてください！`}</p>
    <hr></hr>
    <h2>{`ランチタイムの時間`}</h2>
    <p><strong parentName="p">{`11:30 ～ 15:00(L.O.14:30)`}</strong></p>
    <p>{`「14:30 以降に入店しても断られた」というコメントを見つけたので、L.O の時間には注意して訪問してください！`}</p>
    <hr></hr>
    <h2>{`待ち時間情報`}</h2>
    <LazyImage img={harajukuRyunokoRyuunokoWaitingImage} mdxType="LazyImage" />
    <h3>{`「龍の子」に訪問した方の声`}</h3>
    <p>{`実際に訪問した方が「龍の子」の`}<strong parentName="p">{`待ち時間や行列情報`}</strong>{`について教えてくれました！`}</p>
    <ListBox list={["”ランチタイムはいつも並んでて、地下の店内から階段を通り越して外まで列ができているほどの行列状態。”(Rさん:2020年2月に訪問)"]} mdxType="ListBox" />
    <ListBox list={["11時40分には満席になっていましたが、お料理の提供が早く、客の回転も良いので、ちょっと待てば入れます。満席でも諦めずに待つのが吉です。(Lさん:2020年1月に訪問)"]} mdxType="ListBox" />
    <ListBox list={["平日の12時ごろで降りる階段に列があり、外待ち4名で7.8分で中へ入ることができました。(Aさん:2019年7月に訪問)"]} mdxType="ListBox" />
    <p>{`平日も休日もライン地タイムはかなりの行列の模様。
ただ、`}<strong parentName="p">{`回転率が早く早く入れることが多い`}</strong>{`そうなので、諦めずに待っていただくことをオススメします！`}</p>
    <h3>{`ランチはは予約ができるのか？`}</h3>
    <p>{`ランチの予約はコース(8,000 円~)のみになっています！残念…。
ランチなどで気軽に行く場合、`}<strong parentName="p">{`事前予約をするのは難しい`}</strong>{`と言えそうです。`}</p>
    <p>{`開店前の時間か、行列が少し落ち着く 13 次以降の来店がオススメです！`}</p>
    <hr></hr>
    <h2>{`支払い方法`}</h2>
    <LazyImage img={harajukuRyunokoRyuunokoPaymentImage} mdxType="LazyImage" />
    <BorderBox mdxType="BorderBox">
  <Text as="p" mdxType="Text">
    <Text as="span" fontWeight="bold" mdxType="Text">
      電子マネー：×
    </Text>
    <Text as="span" fontWeight="bold" mdxType="Text">
      クレカ：○
    </Text>
    <Text as="span" fontWeight="bold" mdxType="Text">
      現金：○
    </Text>
  </Text>
    </BorderBox>
    <p>{`クレカも使えるので、キャッシュレスで普段現金を持っていな方でも行きやすいお店になっています！`}</p>
    <hr></hr>
    <h2>{`アクセス`}</h2>
    <p>{`明治神宮前より徒歩 3 分
JR 山手線 原宿駅より徒歩 5 分`}</p>
    <p>{`「龍の子」は嬉しいことに駅近のお店なので、休日の賑わう原宿でもすんなりアクセスできると思います！`}</p>
    <GoogleMap lat={35.6725136} lng={139.6685918} mdxType="GoogleMap" />
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`原宿の中華(四川料理)の人気店「龍の子」について、お得情報をご紹介してきました！
事前にどんな料理を食べるのか、どれくらいの時間帯で行こうか、支払いはどうしようか、といった悩みを解決できる記事になっていれば幸いです！
「龍の子」に行く際は、参考にしていただければと思います！`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;