
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import { harajukuMinoringoTopImage } from "@src/constants/images";
import InstaImage from "@src/components/InstaImage";
import ShopInfoBox from "@src/components/ShopInfoBox";
import { murakamiMasanori } from "@src/constants/authors";
export const meta = {
  path: "/media/harajuku/minoringo",
  title: "【原宿】カレーの行列店「みのりんご 」の無水カレーとは！？",
  description: "原宿カレーの人気店「みのりんご」の無水カレーについてご紹介します。こだわりの調理法で作るカレーは、たくさんスパイスのブレンドだけでなく、牛肉、豚肉、鶏肉、野菜などから出る水分を引き出して、風味が凝縮したカレーになっている。そんなメニューをニーズに合わせてご紹介",
  topImg: harajukuMinoringoTopImage,
  author: murakamiMasanori,
  tags: ["原宿", "有名店", "人気店", "お店の紹介", "カレー"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`原宿カレーの人気店「みのりんご」の無水カレーについてご紹介します。`}</p>
    <p>{`こだわりの調理法で作るカレーは、なんと 12 種類のたスパイスのブレンドし、不要な油は使わずに「牛肉、豚肉、鶏肉、野菜」から出る水分のみを引き出して、作るカレーが大人気なのだとか。`}</p>
    <p>{`風味が凝縮したこだわりのカレーを、お客様のニーズに合わせてご紹介させていただきます。`}</p>
    <h2>{`みのりんごとは`}</h2>
    <InstaImage postId="B_4HafhAfU3" mdxType="InstaImage" />
    <p>{`原宿駅「竹下口」から徒歩 2 分ほど歩くと、黄色い看板をつけたカジュアルなバーのような雰囲気のお店がカレー屋さんが、今回ご紹介するカレーの人気店「みのりんご 」です。`}</p>
    <p>{`ランチ激戦区といわれる原宿で、`}<strong parentName="p">{`連日何十人もの行列を作っているカレー店`}</strong>{`。
後ほど紹介する`}<strong parentName="p">{`インパクトのある見た目`}</strong>{`と`}<strong parentName="p">{`特徴的な作り方`}</strong>{`がオープン当初からたちまち話題となり、たくさんのお客様に支持されています。`}</p>
    <p>{`食べログの評価は 3.7 を超えており、2019 年には、`}<strong parentName="p">{`カレー百名店`}</strong>{`にも選出されており、名実ともに原宿をリードする実力派のカレー店です。`}</p>
    <h3>{`お一人様でも入れる店内`}</h3>
    <p>{`みのりんごの店内はカウンター席が並んでいるので、男女問わずおひとり様でも入れるような店内になっています。`}</p>
    <p>{`「ランチでふらっとカレーを食べたい」という方も、カウンター席があると入りやすくて嬉しいですよね。`}</p>
    <p>{`人気のカレー店なので、お店の外には行列ができていて待ち時間はあるものの、カレーを注文すると 5 分程度でカレーが運ばれてくるので、忙しいときでも安心です。`}</p>
    <hr></hr>
    <h2>{`こだわりの調理法で作るカレー`}</h2>
    <p>{`ここからは、みのりんごの人気の秘密である、カレーの調理法についてご紹介させていただきます！`}</p>
    <h3>{`最低限の油しか使わない`}</h3>
    <p>{`みのりんごのカレーは、市販の油やバターを使用することはなく、`}<strong parentName="p">{`牛と豚の粗挽き肉から出る最低限の油`}</strong>{`のみを使用して作るそうです。`}</p>
    <p>{`水を一切使わず、野菜の水分とココナッツミルクであめ色になったたっぷりの玉ねぎを煮込むので、お肉と野菜の旨味が凝縮されたカレーの味になるそうです。`}</p>
    <h3>{`12 種類のスパイスで作るカレー`}</h3>
    <p>{`みのりんご のカレーは、市販のルーなどは一切使用しないこだわりがあり、12 種類のスパイスをオリジナルブレンドして作るそうです。そのスパイスで作る味わいは、爽やかでスパイシー。`}</p>
    <p>{`さらに、黒胡椒や唐辛子を使用するので、スパイスの香りが口の中に広がった後に、辛さがジワジワと追い上げてくるそう。`}</p>
    <hr></hr>
    <h2>{`みのりんご の人気メニュー`}</h2>
    <p>{`ここからは、そんなこだわりの調理法で作るみのりんごのカレーのメニューについてご紹介します。`}<strong parentName="p">{`テイクアウト情報`}</strong>{`もあるので、ぜひ最後までチェックしてください！`}</p>
    <h3>{`【カレー本来の味を楽しみたいなら】名物キーマカレー`}</h3>
    <InstaImage postId="B7izLnrJt9z" mdxType="InstaImage" />
    <p>{`値段：￥ 900`}</p>
    <p>{`こだわりの調理法で作られた`}<strong parentName="p">{`みのりんご本来の味`}</strong>{`を楽しめるキーマカレーがこちらのメニューです。
100 円で温泉たまごをトッピングをすると、長時間かけて作られたカレーのスパイスと、卵黄のまろやかな風味が口の中で広がる人気メニュー。`}</p>
    <h3>{`【1 番人気なら】とろ~りチーズトッピングのとろとろ温玉のせキーマカレー`}</h3>
    <InstaImage postId="CAnCESFhCr-" mdxType="InstaImage" />
    <p>{`値段：￥ 1,200`}</p>
    <p>{`名物キーマカレーにたっぷりのモッツァレラチーズと温泉たまごをチーズの中にトッピングした、1 番人気のカレーメニューです。`}</p>
    <p>{`ピリッとしたスパイスの風味と唐辛子の辛さを、たっぷりのモッツァレラチーズとたまごが、まろやかな風味で包み込んでくれる。`}</p>
    <p>{`チーズのトッッピングは 200 円で、温泉たまごのトッピングは 100 円です。`}</p>
    <h4>{`スノードームにスプーンで切り込みを入れると...!?`}</h4>
    <InstaImage postId="B6mAUTWnvow" mdxType="InstaImage" />
    <p>{`チーズのスノードームに切り込みを入れルト、とろっとろのチーズと卵黄が溢れ出し、スパイスの効いたキーマカレーがお見合いするシーンがこちらです。
この写真が、インスタグラムでもたくさん UP されているのも納得できますよね。`}</p>
    <hr></hr>
    <h3>{`【マイルドなカレーなら】無水チキンカレー`}</h3>
    <p>{`ごろっと大きくカットされた柔らかく食べ応え抜群のチキンが食べられる無水カレーです。トマトとヨーグルトをベースに作るチキンカレーも、キーマカレーと同じく水は一切使用せず、トマトの水分だけで作っているという驚き...`}</p>
    <p>{`トマトの爽やかな酸味と甘みにヨーグルトの程よいコクが混じり合った、さっぱりとした味わいのカレーです。`}</p>
    <p>{`バターのトッピングが 100 円でできるそうなので、バターチキンカレーが好きな方は、バターをトッピングしてみるのはいかがでしょうか。`}</p>
    <hr></hr>
    <h3>{`【2 種類を同時に！？】みのりんご スペシャル`}</h3>
    <InstaImage postId="CAujzyKAUQr" mdxType="InstaImage" />
    <p>{`値段：￥ 1,000`}</p>
    <p>{`華やかな香りのスパイスが効いたキーマカレーと、マイルドなコクのあるチキンカレーを同時に味わうことができるお得なカレーメニューです！`}</p>
    <p>{`温泉たまごがついているので、割って卵黄と一緒に食べると、カレーの一味違った風味も二度味わうことができます。`}</p>
    <h4>{`チーズをトッピングすると`}</h4>
    <InstaImage postId="B2_1ZIdDms0" mdxType="InstaImage" />
    <p>{`このようにカレーを二度楽しめるだけでなく、チーズのまろやかな風味も楽しめてしまう。そんなカレーメニューです！チーズ好きにはたまらない一品。`}</p>
    <hr></hr>
    <p>{`【スペアリブで作る】辛口ポークカレー`}</p>
    <InstaImage postId="B4FFBHKFcX9" mdxType="InstaImage" />
    <p>{`値段：￥ 900`}</p>
    <p>{`みのりんごのポークカレーは薄切りの豚肉ではなく、じっくりと煮込んだ柔らかい食感の大きなスペアリブでポークカレーを作ります。カレーは野菜や果物、ミルクをスパイスと一緒に煮込んだ辛口に仕上がっていて、こちらも水を使用せずに作る旨味の凝縮されたカレー。`}</p>
    <hr></hr>
    <h3>{`【一見では食べられない！？】激辛ビーフカレー`}</h3>
    <InstaImage postId="B0csTzYDSoy" mdxType="InstaImage" />
    <p>{`牛すね肉とたっぷりの香味野菜、果物を丸 3 日間煮込み、さらにあめ色玉ねぎとスパイスを入れて 1 週間煮込むという手間暇かけて旨味が凝縮されたスパイスの効いた激辛カレー。`}</p>
    <p>{`過去に一度、`}<strong parentName="p">{`辛口ポークカレーを食べたことがある人でないと注文できない`}</strong>{`と噂のカレーだそうで、辛口カレーが食べたい方には必見です！`}</p>
    <h3>{`テイクアウト情報`}</h3>
    <p>{`みのりんごのカレーはテイクアウトでご自宅やオフィスでも楽しむことができます！
「行列に並ぶのが苦手な方」「自宅でゆっくり食べたい方」「仕事で時間が取れない方」には、こちらのテイクアウトメニューも活用してみてください！`}</p>
    <h4>{`キーマカレー弁当`}</h4>
    <InstaImage postId="CAcpwdNgwRO" mdxType="InstaImage" />
    <h4>{`キーマカレー＆牛ステーキ弁当`}</h4>
    <InstaImage postId="B__cDJUjean" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`店舗情報`}</h2>
    <ShopInfoBox shopId="br5tsra23akg00ac688g" mdxType="ShopInfoBox" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;