
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import QuoteBox from "@src/components/QuoteBox";
import WaitingTimeListBox from "@src/components/WaitingTimeListBox";
import LazyImage from "@src/components/LazyImage";
import GoogleMap from "@src/components/GoogleMap";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import ShopInfoBox from "@src/components/ShopInfoBox";
import { murakamiMasanori } from "@src/constants/authors";
import {
  harajukuTheGreatBurgerAppearanceInTelephoneImage,
  harajukuTheGreatBurgerAppearanceInImage,
  harajukuTheGreatBurgerApperanceOutImage,
  harajukuTheGreatBurgerBugerImage,
  harajukuTheGreatBurgerStaffImage,
  harajukuTheGreatBurgerTopImage,
} from "@src/constants/images";
import { meta as harajukuPageMeta } from "./index.mdx";
export const meta = {
  path: "/media/harajuku/the_great_burger",
  title: "【原宿】行列のできる原宿ランチの人気店ザ グレートバーガーの秘密",
  description: "「原宿 ランチ」で調べると必ず出てくるザ グレートバーガーは、お店に到着すると、休日はいつも大行列。なぜ原宿に超人気の行列店が生まれたのかが気になったので、その秘密を調べてみると予想外の発見があったので、是非確認してみてください！",
  topImg: harajukuTheGreatBurgerTopImage,
  author: murakamiMasanori,
  tags: ["行列店", "ハンバーガー", "駅近", "おしゃれな内装", "デート", "裏原"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`【人気の秘密 ①】ザ グレートバーガーのこだわり`}</h2>
    <p>{`原宿駅、明治神宮前駅から徒歩 5 分。`}</p>
    <p>{`ザグレートバーガーといえば、`}<strong parentName="p">{`「有名なハンバーガーショップ」「雰囲気がおしゃれ」`}</strong>{` といった口コミや紹介サイトもたくさんありますが、そこではないと思っています。
ランチ激戦区の原宿ランチで選ばれ続けている一番の理由は、何よりも`}<strong parentName="p">{`オーナーのこだわり`}</strong>{`にあると思います。`}</p>
    <p>{`外装や内装の一つ一つのアイテムにストーリーがあり、ハンバーガーのバンズ(パン)にもオーナーの試行錯誤と思いがあります。`}</p>
    <p>{`そんなこだわりが、年月を経て食べログのハンバーガー百名店にも選ばるようにもなり、休日はいつも原宿で大行列を作っているのだと思います！`}</p>
    <hr></hr>
    <h2>{`【人気の秘密 ②】独自開発のバンズ`}</h2>
    <p>{`バンズとはハンバーガーのパンのことを指します。
ハンバーガー屋さんがパン屋さんにバンズを外注するお店が多い中、ザ グレートバーガーのオーナーの車田さんは、バンズもレシピから考え、寝る間も惜しんで試行錯誤を繰り返したそう。`}<strong parentName="p">{`その期間、なんと半年。`}</strong></p>
    <QuoteBox cite="https://www.cocacola.co.jp/stories/thegreatburger" mdxType="QuoteBox">
  <p>
    「意識したのはモチモチ感とフワフワ感のバランスです。食べ応えは大切だけど、パンがモチモチしすぎるとうまく飲め込めない。フワフワ感を重視し過ぎると、今度は物足りなさを感じてしまう。ちょうどいいバランスを探るのに苦労しました」
  </p>
    </QuoteBox>
    <p>{`そんな思いの末にできたバンズの食感を一度でも体験してみたいからこそ、原宿の激戦区の中でも連日行列が絶えず、人気店であり続けられるではないでしょうか。`}</p>
    <h3>{`そんなバンズを使った人気メニューがこちら`}</h3>
    <p><strong parentName="p">{`【人気メニュー】ベーコンチーズバーガー 1,250 円(税別)`}</strong></p>
    <LazyImage img={harajukuTheGreatBurgerBugerImage} alt="abc" mdxType="LazyImage" />
    <p>{`試行錯誤の上で完成したバンズと、ハンバーガー自体の厚みととろけるチーズ、全体のボリュームの大きさをみると、食べる前からワクワクします。
オリジナルの手法で燻製されたベーコンの香りがさらに食欲をそそります。`}</p>
    <hr></hr>
    <h2>{`【人気の秘密 ③】お店の雰囲気へのこだわり`}</h2>
    <h3>{`【ザグレートバーガー 外観】`}</h3>
    <LazyImage img={harajukuTheGreatBurgerApperanceOutImage} alt="abc" mdxType="LazyImage" />
    <p>{`古着屋や雑貨屋がたくさんある場所に、ガラス張りの外観をしたお店が店舗になります。`}</p>
    <p>{`ここもオーナーのこだわりがあり、`}<strong parentName="p">{` 一瞬でここは違う`}</strong>{`、 と感じさせるような西海岸のリアルな世界観を目指しているそうです！`}</p>
    <p>{`休日だと何十人もの女性客カップルやが行列を作っているところを見ることも多いでしょう。`}</p>
    <h3>{`【ザグレートバーガー 内装】`}</h3>
    <LazyImage img={harajukuTheGreatBurgerAppearanceInImage} alt="abc" mdxType="LazyImage" />
    <p>{`店内に入ってみると、昔のアメリカを思わせるような雰囲気で温かみがあり、一つ一つの雑貨もこだわりを感じるものがたくさん揃っているので、注文を待っている間も楽しむことができます！`}</p>
    <h3>{`【受話器を取ると音が鳴る？】`}</h3>
    <LazyImage img={harajukuTheGreatBurgerAppearanceInTelephoneImage} alt="abc" mdxType="LazyImage" />
    <p>{`内装の中でも特に驚くのが、店内に受話器があること。
実際に電話の受話器をとってみると、音が鳴るそうで、インテリアとしてだけでなく、リアルなものを感じられるという点では、やはりこだわりを感じますね！`}</p>
    <hr></hr>
    <h2>{`【人気の秘密 ④】お洒落でスホスピタリティー溢れるスタッフ`}</h2>
    <LazyImage img={harajukuTheGreatBurgerStaffImage} alt="abc" mdxType="LazyImage" />
    <p>{`原宿の中でもおしゃれが集う裏原ということもあって、おしゃれな店員さんが多いとい口コミをよく見かけます。`}</p>
    <p>{`行列店ということもあって、せわしなく働いていますが、バンズについての説明やお勧めを提案してくれるなど、
せっかく楽しみにしていく店だからこそ、ホスピタリティーも高いお店の方がいいですよね。`}</p>
    <p>{`そういった丁寧なコミュニケーションをとってくれるスタッフがいることも激戦区の原宿の中でも行列店としてザグレートバーガー の人気を押し上げている理由の一つなのではないでしょうか。`}</p>
    <QuoteBox cite="https://tabelog.com/tokyo/A1306/A130601/13042654/" mdxType="QuoteBox">
  <p>
    感じの良いスタッフさんから、ザ・グレート和牛のご説明を熱心に感じ良くご提案して頂きましたので、今回はこちらに...
  </p>
  <p>これはこれで非常に美味しいですね。</p>
  <p>
    感じの良いアメリカンスタイルの店内、質の良いスタッフ、安心安定の味の
    burger。
  </p>
    </QuoteBox>
    <hr></hr>
    <h2>{`店舗情報はこちら`}</h2>
    <p>{`原宿駅の表参道口から、または明治神宮駅から出て東南方向に歩いていき、
さらに裏路地のほうに入っていかないといけないため、道に迷って見つけづらいかもしれないです。
Google map を使いながら行くことをおすすめします！`}</p>
    <ShopInfoBox shopId="brag1o223akg00evu3bg" mdxType="ShopInfoBox" />
    <hr></hr>
    <h2>{`最新の行列情報について`}</h2>
    <p>{`人気の行列店だからこそ、せっかくならなるべく並ばずにお店に入りたくないですか？店についても行列だったらせっかくの休みが台無しだと思うので、
狙い目の時間帯をこちらでご紹介します！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30分以内", "12:00~14:00：60~75分待ち", "14:00~：30分以内"]} mdxType="WaitingTimeListBox" />
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`グレートバーガーが原宿で絶えず人気になっている理由をご紹介させていただきたきました。`}</p>
    <p>{`今日ご紹介した`}<strong parentName="p">{`「南カリフォルニアのような雰囲気」「バンズの食感」「インテリアの電話」`}</strong>{`を、是非グレートバーガーに足を運んで体験してみてください！`}</p>
    <p>{`狙い目のタイミングは`}<strong parentName="p">{`開店前か 14 時以降`}</strong>{`など、時間を外していくのがスムーズに入れるとのことです。`}</p>
    <p>{`また、ハンバーガー以外の原宿の人気行列ランチについては下記の記事にまとめてありますので、是非チェックしてみてください！`}</p>
    <LinkWithThumbnail meta={harajukuPageMeta} mdxType="LinkWithThumbnail" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;