
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import GoogleMap from "@src/components/GoogleMap";
import InstaImage from "@src/components/InstaImage";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import { murakamiMasanori } from "@src/constants/authors";
import { meta as theGreatBurgerPageMeta } from "./the_great_burger.mdx";
import { harajukuFellowsTopImage } from "@src/constants/images";
import { meta as harajukuIndexPageMeta } from "./index.mdx";
import ShopInfoBox from "@src/components/ShopInfoBox";
import WaitingTimeListBox from "@src/components/WaitingTimeListBox";
export const meta = {
  path: "/media/harajuku/fellows",
  title: "【表参道No.1】肉汁あふれるハンバーガー「FELLOWS(フェローズ)」の人気メニュー４選",
  description: "表参道の超人気ハンバーガー店「the burger stand fellows」。ザグレートバーガーとトップを争うFELLOWS(フェローズ)は、なんと、３年連続で食べログハンバーガー部門の100名店に選ばれています。FELLOWS(フェローズ)のハンバーガーを求めて、表参道まで足を運ぶ理由は、そのパテの焼き方に秘密にありました！",
  topImg: harajukuFellowsTopImage,
  author: murakamiMasanori,
  tags: ["原宿", "有名店", "人気店", "お店の紹介", "ハンバーガー"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`「FELLOWS(フェローズ)」とは！？`}</h2>
    <p>{`表参道の超人気ハンバーガー店「the burger stand fellows」。`}</p>
    <p>{`FELLOWS(フェローズ)は、`}<strong parentName="p">{`３年連続`}</strong>{`で食べログハンバーガー部門の`}<strong parentName="p">{`百名店`}</strong>{`に選ばれています。`}</p>
    <p>{`FELLOWS(フェローズ)のハンバーガーを食べたくなる理由は、`}<strong parentName="p">{`パテの焼き方`}</strong>{`、`}<strong parentName="p">{`待ち時間の楽しみ`}</strong>{`にあります！`}</p>
    <p>{`今回は、その FELLOWS(フェローズ)の秘密に迫りたいと思いました！`}</p>
    <h3>{`３年連続食べログ百名店に選ばれる人気店`}</h3>
    <p>{`表参道から 3 分ほど歩くと、路地裏にレトロな外観をしたお店が `}<strong parentName="p">{`「FELLOWS(フェローズ)」`}</strong>{`の目印です。`}</p>
    <InstaImage postId="BzhkhswlBFJ" mdxType="InstaImage" />
    <p><strong parentName="p">{`「FELLOWS(フェローズ)」`}</strong>{` の食べログの評価は `}<strong parentName="p">{`3.77`}</strong>{` (2020 年 5 月時点)になっており、お客様からの信頼を得ている人気店！`}</p>
    <p>{`原宿・表参道エリアのハンバーガー屋さんで、`}<strong parentName="p">{`ザグレートバーガー`}</strong>{` と比較されるお店になっています。`}</p>
    <p>{`混雑している時はこんな感じで並んでいるそう...。
(行列情報は記事の下に書いておきますので、ご覧ください！)`}</p>
    <InstaImage postId="BTvSIPkhEBz" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`人気メニュー４選`}</h2>
    <h3>{`アボカドベーコンチーズバーガー(1,350 円)`}</h3>
    <p>{`おいしくないわけがない！「FELLOWS(フェローズ)」の１番の人気メニュー。通称：ABC バーガー`}</p>
    <InstaImage postId="BYny3-SF47G" mdxType="InstaImage" />
    <h3>{`ハンバーガー(950 円)`}</h3>
    <p>{`パテの味をしっかり楽しみたい方は、こちらの元祖ハンバーガーを食べましょう！`}</p>
    <InstaImage postId="BXVGxqhBIK3" mdxType="InstaImage" />
    <h3>{`ダブルパティーチーズバーガー(1,800 円)`}</h3>
    <p>{`ガッツリいきたい！という方には、ダブルパティーがおすすめ！`}</p>
    <InstaImage postId="BQxk9u2gaCt" mdxType="InstaImage" />
    <h3>{`チーズエッグバーガー(1,250 円)`}</h3>
    <p>{`アメリカンなハンバーガーが食べたい方はエッグをトッピングしましょう！`}</p>
    <InstaImage postId="BJhp1FlBwXe" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`人気の秘密はパテの焼き方！？`}</h2>
    <p>{`「FELLOWS(フェローズ)」の１番の人気の秘密と言っても過言ではないのが、`}<strong parentName="p">{`パテの焼き方`}</strong>{`です。`}</p>
    <p>{`パテといえば、鉄板で焼くでしょ！というのが一般的だと思いますが、なんと「FELLOWS(フェローズ)」では`}<strong parentName="p">{`炭火`}</strong>{`でパテを焼きます！`}</p>
    <p>{`だからこそ、食べた時の所感や、炭火焼独特の風味が口の中に広がり、そこから `}<strong parentName="p">{`溢れんばかりの肉汁`}</strong>{` が口の中に広がる。
想像するだけでヨダレが出てしまいそうになりませんか？笑`}</p>
    <p>{`そんなハンバーガーを求めて、連日行列ができているのだと思います！`}</p>
    <hr></hr>
    <h2>{`こだわりのアイテムがたくさんある店内！`}</h2>
    <p>{`FELLOWS(フェローズ)は３階建ての一軒家で、店内ではでこだわりのアイテムを色んなところで見つけることができます！`}</p>
    <h3>{`例えば、こんな壁紙`}</h3>
    <InstaImage postId="B8jjqnJBvjh" mdxType="InstaImage" />
    <h3>{`つい触ってしまいたくなるような FELLOWS の置物！`}</h3>
    <InstaImage postId="B6DZXTXFEr8" mdxType="InstaImage" />
    <h3>{`BAR RULES という文言も気になります,,,`}</h3>
    <InstaImage postId="BmBJqJnlKA4" mdxType="InstaImage" />
    <h3>{`入り口にはこんなものも！`}</h3>
    <InstaImage postId="BcQz34iju8N" mdxType="InstaImage" />
    <p>{`待っている間もインテリアで楽しむことができるのが、FELLOWS の魅力です！`}</p>
    <p>{`そんなことを思って、FELLOWS(フェローズ)では内装にこだわりを持っているのではないでしょうか。`}</p>
    <hr></hr>
    <h2>{`アクセス`}</h2>
    <p>{`表参道の A1 出口から 2,3 分という好立地！
３階建ての建物なので、すぐに見つけられます...`}</p>
    <GoogleMap lat={35.6650146} lng={139.7080983} mdxType="GoogleMap" />
    <hr></hr>
    <h2>{`行列情報`}</h2>
    <p>{`休日のランチの行列情報が他のサイトに掲載されていましたので、こちらに載せておきます！
狙い目は早い時間か遅めの時間ですね...！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：60~75 分待ち", "14:00~：30 分以内"]} mdxType="WaitingTimeListBox" />
    <LinkWithThumbnail meta={harajukuIndexPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`表参道のハンバーガーの人気店、FELLOWS(フェローズ)についてお伝えしてきました！
ガッツリお肉が食べたい方、普段とは違った炭火焼のパテを楽しみたい方は、行列情報をチェックしてから、是非足を運んでみてくださいー！`}</p>
    <ShopInfoBox shopId="bredp9223akg00f6dt4g" mdxType="ShopInfoBox" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;