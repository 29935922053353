import React from "react";
import { List, ListItem, Text } from "@chakra-ui/react";
import BorderBox from "./BorderBox";

interface Props {
  waitingTimes: string[];
}

export default function WaitingTimeListBox({ waitingTimes }: Props) {
  const waitingTimeList = waitingTimes.map((w) => (
    <ListItem key={w}>{w}</ListItem>
  ));
  return (
    <BorderBox>
      <Text
        fontWeight="bold"
        mb={1}
        pb={1}
        borderBottom="solid 1px"
        borderColor="gray.200"
      >
        ランチ行列情報
      </Text>
      <List>{waitingTimeList}</List>
    </BorderBox>
  );
}
