
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import { harajukuLunchTopImage } from "@src/constants/images";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import WaitingTimeListBox from "@src/components/WaitingTimeListBox";
import InstaImage from "@src/components/InstaImage";
import { murakamiMasanori } from "@src/constants/authors";
import { meta as theGreatBurgerPageMeta } from "./the_great_burger.mdx";
import { meta as minoringoPageMeta } from "./minoringo.mdx";
import { meta as mokubazaPageMeta } from "./mokubaza.mdx";
import { meta as naprePageMeta } from "./napre.mdx";
import { meta as fellowsPageMeta } from "./fellows.mdx";
import { meta as afuriPageMeta } from "./afuri.mdx";
import { meta as rainbowPancakePageMeta } from "./rainbow_pancake.mdx";
import { meta as ryunokoPageMeta } from "./ryunoko.mdx";
import BorderBox from "../../../components/BorderBox";
import {Link, Text} from "@chakra-ui/react";
export const meta = {
  path: "/media/harajuku",
  title: "【厳選】原宿の一度は行きたい行列のできる超人気おすすめランチ13選！",
  description: "原宿エリアの各ジャンル食べログ3.7以上のおすすめ人気行列店を厳選！絶品料理に加えて時間帯ごとの待ち時間も掲載しているので、空いている時間に原宿の人気店の味を楽しみましょう！",
  topImg: harajukuLunchTopImage,
  author: murakamiMasanori,
  tags: ["原宿", "行列店", "人気店", "まとめ記事"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`原宿ランチなら、`}<strong parentName="p">{`普段行けない行列ができる人気店`}</strong>{`に行きたいけど、選択肢が多く、待ち時間も分からなくて選べないといった悩みはありませんか？
そんなあなたに原宿で行列が 10 人以上並んでいた`}<strong parentName="p">{`食べログ 3.7 以上`}</strong>{`の人気店を厳選してご紹介します！`}</p>
    <p><strong parentName="p">{`待ち時間も店舗ごとに掲載`}</strong>{`しているので、空いている時間を事前に一眼でチェックできます！情報収集して、普段はいけない行列できるの人気店で、楽しい休日ランチを過ごしてください！`}</p>
    <BorderBox mdxType="BorderBox">
    <Text as="p" my={2} fontWeight="bold" mdxType="Text">
        [関連サービス情報]
    </Text>
    <Text as="p" my={2} mdxType="Text">
        <Link href="https://www.shing.jp" color="pink.500" mdxType="Link">
            おすすめマットレスの比較・検索サービス「SHING」
        </Link>
        <Text as="span" mdxType="Text">
            をリリースしました！
        </Text>
    </Text>
    <Text as="p" mdxType="Text">
        <Text as="span" mdxType="Text">
            SHINGはおすすめマットレスの紹介のみならず、比較、検索機能も備えたサービスです。睡眠の質を上げたいという方、どのマットレスがおすすめか分からない方は是非ご利用下さい！
        </Text>
    </Text>
    </BorderBox>
    <hr></hr>
    <h2>{`【バーガー】ザ・グレートバーガー`}</h2>
    <InstaImage postId="BvPENkHFewl" mdxType="InstaImage" />
    <InstaImage postId="BnSx-4OHuzV" mdxType="InstaImage" />
    <h3>{`3 年連続百名店のハンバーガー`}</h3>
    <p>{`原宿のハンバーガーで知らない人はいないハンバーガーの名店「ザ・グレートバーガー」。オーナーが半年かけて作成したバンズやボリューム感のあるハンバーガーを求めて、連日行列ができている人気店。`}</p>
    <h3>{`人気ランチメニューはアボカドモッツァレラバーガー`}</h3>
    <InstaImage postId="BbGD99MnyZT" mdxType="InstaImage" />
    <p>{`値段；1,800 円`}</p>
    <p>{`塩気のあるパテにアボカドとチーズがふんだんに使われた No.１のメニュー！ボリューム感と味を求めて行列ができている一品です！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：60~75 分待ち", "14:00~：30 分以内"]} mdxType="WaitingTimeListBox" />
    <p>{`休日に長蛇の列を作る理由はまさにそのこだわりにあると思うので、是非、ザ・グレイトバーガー の記事も色々チェックしてみてください！`}</p>
    <LinkWithThumbnail meta={theGreatBurgerPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【バーガー】THE BURGER STAND FELLOWS`}</h2>
    <InstaImage postId="Byt-UwqhBqw" mdxType="InstaImage" />
    <InstaImage postId="Ba_QdXFA-Z0" mdxType="InstaImage" />
    <h3>{`炭火が香るジューシーなパテ`}</h3>
    <p>{`3 年連続百名店に選ばれるハンバーガーの名店。行列ができる原宿エリアの人気ハンバーガーの秘密は、そのジューシーなパテにあります。冷凍の肉を使わず、炭火で一枚ずつ丁寧に焼くことで旨味を引き出す。
そんなハンバーガーを求めて連日行列ができるハンバーガーの人気店です。`}</p>
    <h3>{`人気ランチメニューはアボカドベーコンチーズバーガー`}</h3>
    <InstaImage postId="CA7T1Q5pITr" mdxType="InstaImage" />
    <p>{`値段：1,800 円
ジューシーなパテだけでなく香ばしいベーコンとアボカドの甘みを堪能できる人気の一品、通称 ABC バーガー。フレンチフライとドリンクがついての値段になるので、ボリューム感と味を考えると`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：60~75 分待ち", "14:00~：30 分以内"]} mdxType="WaitingTimeListBox" />
    <LinkWithThumbnail meta={fellowsPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【カレー】みのりんご`}</h2>
    <InstaImage postId="CBierTmDVVz" mdxType="InstaImage" />
    <p>{`原宿駅から徒歩 2 分`}</p>
    <p>{`お店の外観よりも、まずその行列の多さに驚くことが多いです。席数が少ないということもあり、休日だと 1 時間待ちは当たり前に並ぶそうです。`}</p>
    <p>{`その行列を作る理由の一つに、`}<strong parentName="p">{`これはカレーなのか？笑`}</strong>{`と常識を覆す一品があります！`}</p>
    <p><strong parentName="p">{`白いドームのような形をした`}</strong>{`、チーズキーマカレーです。`}</p>
    <p>{`食べた時に独特なスパイスの香りも感じるこのカレーは、インスタに思わず載せたくなるような至極の一品。`}</p>
    <p>{`ランチは早い時間でも行列を作るため、遅めの 14 時以降が狙い目だそうなので、是非空き時間を見つけてチャレンジしてみてください！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：60~75 分待ち", "14:00~：30 分以内"]} mdxType="WaitingTimeListBox" />
    <LinkWithThumbnail meta={minoringoPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【カレー】MOKUBAZA`}</h2>
    <InstaImage postId="BlsE6PuhqwR" mdxType="InstaImage" />
    <p>{`原宿駅から徒歩 9 分`}</p>
    <p>{`住宅街の一角に位置し、お洒落なカフェのような外観をした MOKUBAZA も、
`}<strong parentName="p">{`休日は大行列`}</strong>{`を作ることで有名です。`}</p>
    <p><strong parentName="p">{`ドーム型をしたモッツァレラキーマカレー`}</strong>{`の頂上に、
卵黄が乗ったこのカレーは本当に美しいの一言。`}</p>
    <p><strong parentName="p">{`写真を撮ってインスタにあげない選択肢はない`}</strong>{`この一品を求めて、連日行列ができているのもうなづけますよね！
麒麟の川島さんや元 AKB48 の髙橋みなみさんもご来店されているようなので、有名人御用達なのかもしれません！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：60 分待ち", "14:00~：30 分以内"]} mdxType="WaitingTimeListBox" />
    <LinkWithThumbnail meta={mokubazaPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【イタリアン】 ナプレ 南青山本店`}</h2>
    <InstaImage postId="CAwdI6NnR_D" mdxType="InstaImage" />
    <InstaImage postId="CB0Yk-rAA1K" mdxType="InstaImage" />
    <p>{`白レンガを基調としたお城のような外観を見ると、休日のランチは開店前から長蛇の列を作っている。`}</p>
    <p>{`実は、日本で初めて本格的なナポリピッツァが食べられるお店として、1999 年に青山にオープンしたのがナプレ。`}</p>
    <p>{`イタリアから取り寄せた自慢の食材をふんだんに使ったピザやパスタなど、ランチもリーズナブルに食べれるのが魅力！`}</p>
    <p>{`その魅力や噂を聞きつけて、ランチではいつも長蛇の列を作っていますが、席数が多いため開店前の時間帯は意外と早く入れることが多いそうです！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：60~75 分待ち", "14:00~：30 分以内"]} mdxType="WaitingTimeListBox" />
    <LinkWithThumbnail meta={naprePageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【イタリアン】ピッツァ ケベロス`}</h2>
    <InstaImage postId="B9BCBRqjP4M" mdxType="InstaImage" />
    <h3>{`2 年連続百名店選出の本格派ピザ`}</h3>
    <p>{`原宿駅から徒歩 5 分の距離で、窯で焼く本格的なピザランチが食べられるお店。
食べログピザ百名店に 2 年連続(2018,2019 年)選出されているなど、近年ますますその人気が向上し、行列ができているお店です！`}</p>
    <h3>{`人気のランチメニューはマリナーラ`}</h3>
    <InstaImage postId="BWQZIQEjZ9K" mdxType="InstaImage" />
    <p>{`値段：1,000 円`}</p>
    <p>{`「トマト、バジル、熟成ニンニク、オレガノ(ハーブのような葉っぱ)」の４種類しか使わないマリナーラが一番人気。
インスタグラムで検索すると、ランチでマリナーラを注文している写真をたくさん見ることができます！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：30分以内", "14:00~：待ち時間なし"]} mdxType="WaitingTimeListBox" />
    <hr></hr>
    <h2>{`【イタリアン】ピッツェリア カンテラ`}</h2>
    <InstaImage postId="B-qv2UCg_vq" mdxType="InstaImage" />
    <h3>{`3 年連続百名店に選出される人気店`}</h3>
    <p>{`立地の良さとお洒落なピザが食べられることで、女子会などでもよく使われるお店です。食べログの評価も 3.68(2020 年 5 月時点)で、`}<strong parentName="p">{`3 年連続食べログのピザ百名店`}</strong>{`に選出されている人気なお店だからこそ、土日には行列ができることも。`}</p>
    <p>{`###  人気ランチはカラブレーゼ`}</p>
    <InstaImage postId="B0lOr9-Diiy" mdxType="InstaImage" />
    <p>{`値段：1,880 円`}</p>
    <p>{`オーガニック食材を使った全粒粉で作る生地に、トマトソースをベースに、赤玉ねぎ、青唐辛子、揚げナスを使ったボリューム満点のピザ！ピリッとした風味に、トマトの酸味やナスの甘味が広がる一品。
行列に並んでもおしゃれな空間で美味しいピザランチをご堪能ください。`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：待ち時間なし", "12:00~14:00：30分以内", "14:00~：待ち時間なし"]} mdxType="WaitingTimeListBox" />
    <hr></hr>
    <h2>{`【ラーメン】AFURI 原宿　`}</h2>
    <InstaImage postId="B6E_q7JpD_s" mdxType="InstaImage" />
    <h3>{`ミシュランに掲載されたラーメン店`}</h3>
    <p>{`世界の格付けのミシュランに 2015 年に掲載された実績を持つ世界的にも有名なラーメン店。お客様の半分は外国という人気っぷ理で、普段から行列ができていることでも有名です。`}</p>
    <h3>{`人気のランチメニューは柚子塩らーめん`}</h3>
    <InstaImage postId="B-sUwkBlFsE" mdxType="InstaImage" />
    <p>{`値段：980 円`}</p>
    <p>{`阿夫利山から湧き出る天然の水を使った透き通るスープと、コシのあるヘルシーな自家製の麺を選ぶことができます。その上に、ゆずの香りが口の中に広がっていくこの体験は、ぜひ現地で行列に並んででも食べる価値はあるのではないでしょうか…！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：30分~60", "14:00~：待ち時間なし"]} mdxType="WaitingTimeListBox" />
    <LinkWithThumbnail meta={afuriPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【中華】龍の子`}</h2>
    <InstaImage postId="CAWfSrcgcAX" mdxType="InstaImage" />
    <h3>{`本格四川料理を原宿で`}</h3>
    <p>{`「中華といえば四川料理」という方も多いのではないでしょうか。1977 年創業の老舗で、食べログの評価はなんと 3.75(2020 年 5 月時点)の人気店なので、土日は行列ができることもしばしば。本場四川の調味料を使って作る料理は、`}<strong parentName="p">{`辛さと痺れ`}</strong>{`のどちらも楽しめます！`}</p>
    <p>{`※四川料；山椒・唐辛子をふんだんに使ったピリッとした刺激的な料理`}</p>
    <h3>{`人気のランチメニューは麻婆豆腐`}</h3>
    <InstaImage postId="CBptCZ_DPK_" mdxType="InstaImage" />
    <p>{`値段：1,320 円`}</p>
    <p>{`通常だと 1,980 円するメニューですが、ランチメニューだと`}<strong parentName="p">{`ライス、スープ、お新香`}</strong>{`がついていて、この値段というお得な価格です！この麻婆豆腐を求めて行列ができていることも…!`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：30~60 分待ち", "14:00~：待ち時間なし"]} mdxType="WaitingTimeListBox" />
    <LinkWithThumbnail meta={ryunokoPageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【中華】 希須林 青山`}</h2>
    <InstaImage postId="CB13jqlAIgV" mdxType="InstaImage" />
    <p>{`表参道駅から徒歩 2 分。`}</p>
    <p>{`希須林は高級中華を思わせるシックで温かみのある内装になっており、カップルや女性客で休日ランチを賑わせています。`}</p>
    <p>{`2020 年 3 月の雨の日でも、胡麻香る坦々麺と酢豚のランチを求め、行列が絶えないとの口コミも入っている。`}</p>
    <p>{`中華といえば値段も高いイメージがあるが、値段も他の中華料理店に比べてリーズナブルなのも、お店に行列ができる理由になっている！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：60~75 分待ち", "14:00~：30 分以内"]} mdxType="WaitingTimeListBox" />
    <hr></hr>
    <h2>{`【パンケーキ】レインボーパンケーキ`}</h2>
    <InstaImage postId="B4cbRngBO1S" mdxType="InstaImage" />
    <h3>{`原宿パンケーキといえばここ`}</h3>
    <p>{`パンケーキ激戦区の原宿で一番人気のパンケーキ店で、いつも大行列。インスタグラムを見ると色鮮やかなパンケーキがたくさんありますが、ランチメニューでお食事系のパンケーキが美味しいことで有名です！`}</p>
    <h3>{`人気ランチメニューはサーモン＆アボカド`}</h3>
    <InstaImage postId="B82c6hqBEEr" mdxType="InstaImage" />
    <p>{`値段；1,350 円`}</p>
    <p>{`もともちのパンケーキにレタス、サーモン、アボカド、チーズ、オニオンがそびえ立つように盛り付けられた一品。目玉焼きと一緒に、塩気のあるパンケーキランチになっています。`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：30~60 分待ち", "14:00~：90分以内"]} mdxType="WaitingTimeListBox" />
    <LinkWithThumbnail meta={rainbowPancakePageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h2>{`【パンケーキ】ミカサデコカフェ`}</h2>
    <InstaImage postId="CBvGTP9Jhvv" mdxType="InstaImage" />
    <h3>{`大人が楽しめるパンケーキレストラン`}</h3>
    <p>{`パンケーキだけでなく、チリゾットやローストビーフなど月替りのメニューが楽しめることで若い女性から絶大な人気を得ているカフェレストラン。店内も青を基調として落ち着いた空間で、休日は行列が並ぶことで有名です！`}</p>
    <h3>{`人気のランチメニュはソーセージセット`}</h3>
    <InstaImage postId="CAIRokmpsEb" mdxType="InstaImage" />
    <p>{`値段：1,430 円
もちもとのプレーンパンケーキが 2 枚に、ふわふわのオムレツとソーセージ、トマト、サラダがついた人気メニュー！甘いパンケーキと塩気のある料理を一緒に楽しめる贅沢な一品。`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：30 分以内", "12:00~14:00：60 分待ち", "14:00~：90分分以内"]} mdxType="WaitingTimeListBox" />
    <hr></hr>
    <h2>{`【パンケーキ】ウズナオムオム`}</h2>
    <h3>{`知る人ぞ知る原宿の穴場パンケーキ`}</h3>
    <p>{`パンケーキ激戦区で知る人ぞ知る名店がこのウズナオムオム。甘いパンケーキはもちろんだが、ランチで食べるお食事系パンケーキが人気で、時に行列ができることも。`}</p>
    <h3>{`人気のランチメニューはランチパンケーキ`}</h3>
    <InstaImage postId="B99f_SdJ8P1" mdxType="InstaImage" />
    <p>{`値段：1,150 円`}</p>
    <p>{`スモークサーモンとカマンベールがふんだんに使われたボリューム感のあるお食事系パンケーキ。行列ができることは少ないですが、行列情報をチェックして並ばずに食べましょう！`}</p>
    <WaitingTimeListBox waitingTimes={["11:00~12:00：待ち時間なし", "12:00~14:00：30分以内", "14:00~：待ち時間なし"]} mdxType="WaitingTimeListBox" />
    <hr></hr>
    <p>{`今回は食べログ 3.7 以上の原宿の行列店をご紹介させていただきました。どのお店も間違いない人気店なので、是非原宿でのランチ選びの参考になりましたら幸いです。`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;