
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import {
  harajukuNapreAppearanceOutImage,
  harajukuNapreMixvegetablesImage,
  harajukuNaprePizzaIoroImage,
  harajukuNaprePizzaKalinImage,
  harajukuNaprePizzaMakeImage,
  harajukuNapreQuatroImage,
  harajukuNapreTopImage,
} from "@src/constants/images";
import WaitingTimeListBox from "@src/components/WaitingTimeListBox";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import LazyImage from "@src/components/LazyImage";
import { murakamiMasanori } from "@src/constants/authors";
import { meta as pizzaPageMeta } from "./pizza.mdx";
export const meta = {
  path: "/media/harajuku/napre",
  title: "【イタリア人が認める】行列のできる原宿ナプレで、本場のピザランチを食べてみよう！",
  description: "原宿、表参道、青山のエリアで行列のできるイタリアンやピザといえば、ナプレを思い浮かべる方も多いのではないでしょうか。在日イタリア人もナプレのピザを食べて、「現地より美味しい」と言うほどの味を提供しているナプレだが、そのナプレについて調べてみましたので、是非ご覧ください！",
  topImg: harajukuNapreTopImage,
  author: murakamiMasanori,
  tags: ["原宿", "有名店", "人気店", "お店の紹介", "イタリアン", "食べログ高評価"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`ナプレは日本に初めてナポリピザ焼いた開拓者`}</h2>
    <p>{`ナプレですが、ナポリピザの開拓者と呼ばれていて、1999 年にお店をオープンした当時に、ナポリピザを日本で初めて持ち込んだと言われております。`}</p>
    <hr></hr>
    <h2>{`イタリア人が認める理由`}</h2>
    <p>{`在日イタリア人からも「現地より美味しい」と評価を得ているピザですが、イタリア人の下を唸らせる理由があるので、その３つの理由をまとめてみました！`}</p>
    <h3>{`ナポリの名工が親子二代で作ったピザ窯`}</h3>
    <p>{`お店の味と店舗の味を分ける大きな要因の一つは、ピザ窯でピザを焼くがどうかだと言われています。`}</p>
    <p>{`写真で見てもわかるように、大きな洗練されたピザ窯が 1 階にあります。
450℃ ～ 500℃ の高温で焼き上げるピッツァは、外はカリッ、中はモチモチの食感になります。`}</p>
    <LazyImage img={harajukuNaprePizzaKalinImage} alt="abc" mdxType="LazyImage" />
    <p>{`ナプレはナポリでも有数のピッツァ窯職人である、フェラーラ家の窯職人を呼び寄せて作ったもので、本場親子２代が手掛けた窯を使用しています。
名工・故ナターレ・フェラーラ氏、そして彼の亡き後はその意志を継いだ息子ステファノ・フェラーラ氏によって作り上げられた銘品が、ナプレのピザを作り上げているといえそうです！`}</p>
    <hr></hr>
    <h3>{`イタリアで修行を積んだピザ職人`}</h3>
    <LazyImage img={harajukuNaprePizzaIoroImage} alt="abc" mdxType="LazyImage" />
    <p>{`ナポリ・ピッツァ大会で入賞したピッツァイオーロ(ピザ職人のイタリアでの名称)が焼いてくれる本物のナポリピザ。
2018 年は百名店にも選ばれているピザ職人が 1 日に焼くのはなんと 150〜160 枚！それでも「もっと焼きたい」と思うほどのピザ作りに対するこだわりと長年培った技術で、多くのお客さんを魅了しています！`}</p>
    <hr></hr>
    <h3>{`食材をイタリアから仕入れている`}</h3>
    <LazyImage img={harajukuNapreMixvegetablesImage} alt="abc" mdxType="LazyImage" />
    <p>{`素晴らしい窯と素晴らしい職人が揃っていても、材料がダメだったら味もダメになってしまいます。
ナプレはここでも他店と異なり、イタリア直送のオーガニックの小麦粉やトマト缶、新鮮な野菜や食材を使ってピザを作り上げています！`}</p>
    <p>{`こういった食材が、小麦粉の香りと甘味、香ばしさ、塩味のバランスが良い生地を作り上げています。`}</p>
    <p>{`その食材の本格的な味わいは、イタリアの方が「故郷を思い出す」と言うほど。イタリア本場の味を日本にいても堪能できるのが、人気の秘密！`}</p>
    <hr></hr>
    <h2>{`まるでナポリにいるようなお店の雰囲気`}</h2>
    <LazyImage img={harajukuNapreAppearanceOutImage} alt="abc" mdxType="LazyImage" />
    <p>{`原宿を表参道方面に抜けるとまるでナポリに迷いこんだかのような外観がナプレの目印です。
南イタリアの港町の小さなトラットリアをイメージした店内は、まるでイタリア。`}</p>
    <p>{`一階が厨房で、2,3 階がレストランになっているので、店内でもイタリアのナポリにいるような体験をすることができます。
また、店内の床に敷き詰められているタイルもイタリアから取り寄せたそうで、本格的にイタリアを再現しているといえます。`}</p>
    <p>{`店外のガラス張りからピザ職人がピザを作る姿を見ることができるかも！？`}</p>
    <LazyImage img={harajukuNaprePizzaMakeImage} alt="abc" mdxType="LazyImage" />
    <hr></hr>
    <h2>{`リーズナブルなランチメニュー`}</h2>
    <p>{`ナプレの特徴は、一回の食事でいろんな料理を食べることができるところです。
その上、ランチは 1500 円とリーズナブルな値段になっているので、リピートする方が多いのだとか。`}</p>
    <p>{`ピザの種類も豊富で 25 種ほどのラインナップがあるので、その日の気分に合わせてピザを選んでもいいと思います。
`}<strong parentName="p">{`ピザの種類例`}</strong></p>
    {
      /* Todoスライドショーで表示できるようにする */
    }
    {
      /* Todomargelita→quatro→seafood→micvegetables */
    }
    <LazyImage img={harajukuNapreQuatroImage} alt="abc" mdxType="LazyImage" />
    <hr></hr>
    <h2>{`気になる行列情報`}</h2>
    <p>{`開店前に行列ができることで有名で、20 人近くが待っていることも当たり前だそうです。
他の行列のお店とは違って実は待ち時間が少なかったりするともあるそうで、開店時間のタイミングは席数が多いこともあって、行列の割に待ち時間がほとんどないことも！`}</p>
    <WaitingTimeListBox waitingTimes={["行列情報", "・11:00~12:00：なしor30分以内", "・12:00~13:00：30~60分", "・13:00~14:00；30分以内"]} mdxType="WaitingTimeListBox" />
    <h3>{`おわりに`}</h3>
    <p>{`この記事ではイタリア人が認めるピザの名店、ナプレについてご紹介いたしましたがいかがでしたでしょうか。`}</p>
    <p>{`ナプレ以外のピザの名店についても興味がある方、下記の記事で原宿にあるピザの行列店をまとめていますので是非ご覧ください！`}</p>
    <LinkWithThumbnail meta={pizzaPageMeta} mdxType="LinkWithThumbnail" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;