
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import { harajukuNapreTopImage } from "@src/constants/images";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import InstaImage from "@src/components/InstaImage";
import { murakamiMasanori } from "@src/constants/authors";
import { meta as naprePageMeta } from "./napre.mdx";
import { meta as harajukuPageMeta } from "./index.mdx";
export const meta = {
  path: "/media/harajuku/pizza",
  title: "【原宿・表参道】本格派の絶品ピザが味わえるランチ4選",
  description: "ガッツリ食事がしたい！そんな時はピザが食べたい！でもせっかく原宿・表参道に来たなら、普段食べない本格的なピザが食べたいといったことはありませんか？そんな時に、原宿・表参道エリアで本格派のピザが食べられるお店を、長年調査してきた筆者が厳選してご紹介します！",
  topImg: harajukuNapreTopImage,
  author: murakamiMasanori,
  tags: ["原宿", "イタリアン", "人気店", "まとめ記事", "デート", "女子会", "おしゃれな内装"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`本格派のピザといえば、食材も本場のイタリアから仕入れることで現地の味を再現したり、ピザの大会で数々の成績を残したピザ職人が、特製の窯でピザを作るものになります。`}</p>
    <p>{`ランチでも値段が高くて、本格派のピザを食べるのは少し抵抗がある。原宿や表参道はおしゃれだけど高いイメージがある。そういったことで諦めた経験はありませんか？`}</p>
    <p>{`今回は`}<strong parentName="p">{`絶品ピザの中でも値段がリーズナブル`}</strong>{`で食べられる、食べログ評価の高いお店をご紹介します！`}</p>
    <h2>{`【チーズなしのピザが 1 番人気？】ピッツァ ケベロス`}</h2>
    <h3>{`チーズを使わないピザが 1 番人気という衝撃`}</h3>
    <p>{`そのお店の一番の味を知りたいなら、マリナーラを食べれば分かると言われています。
マリナーラはチーズがなしのピザになるのですが、実はケベロスの 1 番人気のメニューになっていますので、ランチで食べたい一品になっています！`}</p>
    <InstaImage postId="B-Zr1RslN5R" mdxType="InstaImage" />
    <p>{`トマトソース、ガーリック、バジルというシンプルな食材を駆使した、
絶品の元祖ナポリピザが人気の理由となっています。`}</p>
    <h3>{`ピザの 外はカリッと、中はジューシーな食感`}</h3>
    <p>{`独自の薪窯を使用しており、ピザ職人によって作り出されることによって、
本格ピザでしか味わえない食感も人気をつく出している理由だと思います！`}</p>
    <h3>{`食べログ`}</h3>
    <p>{`3.70`}</p>
    <h3>{`値段`}</h3>
    <p>{`1,000 円`}</p>
    <h3>{`アクセス`}</h3>
    <p>{`原宿駅から徒歩５分`}</p>
    <hr></hr>
    <h2>{`【60 年の老舗の味】PIZZERIA SPONTINI カスケード原宿店`}</h2>
    <h3>{`イタリア ミラノにある老舗の人気ピザ`}</h3>
    <p>{`2015 年に海外初の店舗として東京の原宿に一号店がオープンし、知る人ぞ知る人気のお店のピザが日本でも食べられるようになりました！`}</p>
    <InstaImage postId="B-gKE4eJY24" mdxType="InstaImage" />
    <h3>{`生地の厚さはなんと３センチ！？`}</h3>
    <InstaImage postId="Bu3wgtJBryU" mdxType="InstaImage" />
    <p>{`通常のピザの暑さは 1~2 センチ程度ですが、ミラノ風のピザは `}<strong parentName="p">{`3 センチ`}</strong>{`と分厚く、
フワッとしてボリューム感のある食感を楽しむことができます！
インスタ映え間違いなしのピザなので、是非足を運んでください！！`}</p>
    <h3>{`食べログ`}</h3>
    <p>{`3.49`}</p>
    <h3>{`値段`}</h3>
    <p>{`1,000~1,200 円`}</p>
    <h3>{`アクセス`}</h3>
    <p>{`「原宿駅」表参道口より徒歩 2 分`}</p>
    <hr></hr>
    <h2>{`【全粒粉でオーガニックピザ！？】ピッツェリア カンテラ`}</h2>
    <h3>{`都会を一望できる空中テラスでピザを食べよう！`}</h3>
    <p>{`木目調の店内を抜けると、昼には開放的なテラス席から都会を一望することができます！
ランチでちょっと雰囲気の良い開放的な空間で、ピザを食べてみましょう！`}</p>
    <InstaImage postId="BxPLT36j4Bh" mdxType="InstaImage" />
    <h3>{`オーガニック食材を使ったピザが楽しめる！`}</h3>
    <p>{`国産小麦「春よこい」を石臼で挽いた全粒粉ピザや、自然派ワイン、オーガニック食材を生産者から直接に仕入れた食材を用いたタパスなどが愉しめるピッツェリアです！
ピザを食べたいけど、せっかくならヘルシーなものが食べたい方にはおすすめのお店になっています！`}</p>
    <InstaImage postId="BwE64pMDSmM" mdxType="InstaImage" />
    <h3>{`食べログ`}</h3>
    <p>{`3.69`}</p>
    <h3>{`値段`}</h3>
    <p>{`1,000~1,500 円`}</p>
    <h3>{`アクセス`}</h3>
    <p>{`東京メトロ千代田線明治神宮前（原宿）駅 7 番出口　徒歩 3 分
JR 原宿駅徒歩 7 分`}</p>
    <hr></hr>
    <h2>{`【イタリア人も認めるピザ】ナプレ`}</h2>
    <h3>{`イタリアで修行を積んだピザ職人`}</h3>
    <InstaImage postId="B7aQEKuJFwN" mdxType="InstaImage" />
    <p>{`ナポリ・ピザ大会で入賞したピッツァイオーロ(ピザ職人のイタリアでの名称)が焼いてくれる本物のナポリピザ。
2018 年は百名店にも選ばれているピザ職人が 1 日に焼くのはなんと 150〜160 枚！それでも「もっと焼きたい」と思うほど、奥の深い仕事だとピザ作りへの思いと技術が、原宿・表参道エリアで多くのお客さんを魅了しているのだと思います！`}</p>
    <h3>{`食イタリアから仕入れる食材`}</h3>
    <InstaImage postId="B5YDe29p1CI" mdxType="InstaImage" />
    <p>{`素晴らしい窯と素晴らしい職人が揃っていても、材料がダメだったら味もダメになってしまいます。
ナプレはここでも他店と異なり、イタリア直送のオーガニックの小麦粉やトマト缶、新鮮な野菜や食材を使ってピザを作り上げています！`}</p>
    <p>{`その食材の本格的なピザの味わいは、イタリアの方が「故郷を思い出す」と言うほどなので、イタリア本場の味を日本にいても堪能できるのが、原宿・表参道エリアで人気の秘密なのだと思います！`}</p>
    <h3>{`食べログ`}</h3>
    <p>{`3.77`}</p>
    <h3>{`値段`}</h3>
    <p>{`1,000~1,500 円`}</p>
    <h3>{`アクセス`}</h3>
    <p>{`【表参道駅】徒歩３分（Ｂ３地上口近く）`}</p>
    <LinkWithThumbnail meta={naprePageMeta} mdxType="LinkWithThumbnail" />
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`この記事では、原宿のピザの名店をまとめてまいりましたが、いかがでしたでしょうか？
`}<strong parentName="p">{`普段は行かない原宿・表参道の名店で、本格派のピザを是非食べてみてください！`}</strong></p>
    <p>{`また、ピザ以外にも原宿にはおいしいランチが盛り沢山。
下記の記事で各ジャンルの行列店についてまとめていますので、是非ご覧ください！`}</p>
    <LinkWithThumbnail meta={harajukuPageMeta} mdxType="LinkWithThumbnail" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;