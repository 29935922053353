
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import InstaImage from "@src/components/InstaImage";
import ListBox from "@src/components/ListBox";
import GoogleMap from "@src/components/GoogleMap";
import BorderBox from "@src/components/BorderBox";
import { harajukuAfuriAfuriTopImage } from "@src/constants/images";
import { murakamiMasanori } from "@src/constants/authors";
export const meta = {
  path: "/media/harajuku/afuri",
  title: "【原宿】ミシュランなのに安く食べられるラーメン【阿夫利(afuri)】を徹底分析！",
  description: "ミシュランに掲載実績のあるラーメンを食べたことはありますか？原宿の人気ラーメン店【阿夫利(afuri)】は、ミシュランに掲載実績がある名店にもかかわず、安くラーメンが食べられる珍しいお店です。ラーメン店とは思えないたくさんの「驚き」があるお店【阿夫利(afuri)】を徹底分析してみました！",
  topImg: harajukuAfuriAfuriTopImage,
  author: murakamiMasanori,
  tags: ["ラーメン", "有名店", "おしゃれな内装", "あっさり", "人気店", "お店の紹介", "ミシュラン掲載", "1人メシ"]
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Text = makeShortcode("Text");
const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ミシュランにも掲載されたことがある原宿の人気ラーメン店【阿夫利(afuri)】は、選ばれる理由がたくさんありました。`}</p>
    <p>{`「ミシュランなのに安く食べられる」「ラーメン屋とは思えないような雰囲気」「外国人からも愛されるメニュー」「こだわりの食材」など、【阿夫利(afuri)】を徹底分析してみたので、ぜひご一読ください！`}</p>
    <h2>{`ミシュランガイドに掲載される店`}</h2>
    <p><strong parentName="p">{`ラーメン屋でミシュラン`}</strong>{`というイメージはあまりない方も多いのではないでしょうか！？
原宿を中心として日本で 14 店舗、海外で 5 店舗を展開する`}<strong parentName="p">{`阿夫利(afuri)`}</strong>{`は、ラーメン屋として`}<strong parentName="p">{`2015 年ミシュランガイド東京`}</strong>{`にノミネートされている名店になります！`}</p>
    <h3>{`ミシュランガイドとは`}</h3>
    <InstaImage postId="B_vipVzJ4q-" mdxType="InstaImage" />
    <p>{`世界中のレストランやホテルなどの料理を、三つの星で格付けし、それを掲載したガイドブック。多くの飲食店が一度は掲載されたいと思う世界で格式の高いガイドブックの一つ。星野基準は下記の通りになっている。`}</p>
    <h4>{`ミシュランガイド星の基準`}</h4>
    <ListBox list={["三つ星：「そのために旅行する価値のある卓越した料理」", "二つ星：「遠回りしてでも訪れる価値のある素晴らしい料理", "一つ星：「近くに訪れたら行く価値のある優れた料理」"]} mdxType="ListBox" />
    <hr></hr>
    <h2>{`原宿駅から徒歩３分のアクセス`}</h2>
    <p>{`明治神宮駅からも徒歩５分で、駅を降りて北に向かって一本道なのでわかりやすいのが特徴です！`}</p>
    <GoogleMap lat={35.673022} lng={139.703802} mdxType="GoogleMap" />
    <hr></hr>
    <h2>{`ラーメンの値段が意外と安い`}</h2>
    <p>{`ミシュランガイドに掲載されていると聞くと、`}<strong parentName="p">{`高級のお店`}</strong>{`というイメージを持つことが多いのではないでしょうか？
「高いお金を払わないといけない！」というイメージがあるからこそ、ミシュランと聞いてもどこか馴染みがない遠い存在。
しかし、ミシュランの料理を安くで食べられることが、阿夫利(afuri)の魅力です！`}</p>
    <h3>{`人気の柚子塩らーめん：980 円(税込)`}</h3>
    <p>{`定番の人気メニューを食べても、1.000 円でお釣りがきてしまいます！`}</p>
    <InstaImage postId="B_fC6_QAd7i" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`カフェのような雰囲気のお店`}</h2>
    <h3>{`外観は都会的なカフェのよう`}</h3>
    <p>{`ラーメンといえば外観も老舗の雰囲気で、煙がモクモクとたつ慌ただしいイメージ。
阿夫利(afuri)はそんなイメージとは少し違った白と黒を基調としたカフェのような都会的な印象。
違った体験ができるのも、訪れたくなる理由の一つですよね！`}</p>
    <InstaImage postId="Bgh2QGNFH2f" mdxType="InstaImage" />
    <h3>{`内観は落ち着いた雰囲気`}</h3>
    <p>{`白を基調とした壁に落ち着いた色の照明で、リラックスできる雰囲気。
オープンキッチンで作っている様子を見ることができるので、待ち時間を楽しむことができます！`}</p>
    <h3>{`店内の BGM は洋楽`}</h3>
    <p><strong parentName="p">{`ラーメンで洋楽`}</strong>{`というギャップも阿夫利(afuri)のこだわりの一つ。
落ち着いた洋楽の音楽でリラックスしながら食べるラーメンはまさに非日常空間。`}</p>
    <hr></hr>
    <h2>{`行列が避けられる狙い目の時間`}</h2>
    <p><strong parentName="p">{`ミシュラン掲載のあるお店で、ラーメンの値段が安い`}</strong>{`
これを知るとみんな食べたくなってしまうので、その分たくさんの人が阿夫利(afuri)に訪れます。
時間帯によっては長い行列ができていて、1 時間程度待つことも。
天気が悪い日、極寒の日苦痛になりますよね。`}</p>
    <p>{`せっかく楽しみにしてきたのに、そこで気分を落としたくないからこそ、入りやすい時間帯や行列情報について調べてみました！`}</p>
    <h3>{`行列情報`}</h3>
    <p>{`12 時になると既に席は満席で、11 時半だと席の半分が外国人で埋まっていた(2019/12)
12 時になると既に６組が外で待っていた(2019/11)`}</p>
    <hr></hr>
    <h2>{`注文は店内の券売機で`}</h2>
    <p>{`阿夫利(afuri)原宿店では、店内に券売機が設置されており、券売機からメニューを選んでお支払いを済ませ、席で渡す仕組みになっています。`}</p>
    <InstaImage postId="BPr0inwBO9E" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`人気のメニュー`}</h2>
    <h4>{`柚子塩らーめん：980 円(税込)`}</h4>
    <p>{`1 番人気のメニューになります！さっぱりとした柚子の風味と、コシのある特製麺を楽しめます！`}</p>
    <InstaImage postId="BoMCnWklzYm" mdxType="InstaImage" />
    <h4>{`彩り野菜のヴィーガンらーめん：1,350 円(税込)`}</h4>
    <p>{`外国人や女性の方に人気の産地直送の野菜をふんだんに使ったラーメン！`}</p>
    <InstaImage postId="BuxDlmBnP4b" mdxType="InstaImage" />
    <h3>{`つけ麺`}</h3>
    <h4>{`柚子露つけ麺：1,280 円(税込)`}</h4>
    <p>{`国産の鶏と野菜をベースに魚介醤油で甘めに仕上げた冷製ダレに、阿夫利(afuri)らしく柚子の風味が効いた特製つけ麺！`}</p>
    <InstaImage postId="B-D8KBYgSLC" include={[1]} mdxType="InstaImage" />
    <h3>{`ご飯・トッピング`}</h3>
    <h4>{`炙りコロチャーシュー飯：580 円(税込)`}</h4>
    <p>{`人気の`}<strong parentName="p">{`炙りチャーシュー`}</strong>{`の甘みと柚子風味の大根おろしを交えた一品！`}</p>
    <InstaImage postId="B3GV1kBB9u2" mdxType="InstaImage" />
    <hr></hr>
    <h2>{`ラーメンの中身を気分に合わせて変更できる`}</h2>
    <p>{`体調によってたべたいものが変わるときってありますよね。
がっつり濃いものが食べたい日もあれば、あっさりしたものが食べたい日も。
阿夫利(afuri)はそれを叶えてくれるお店になっています！`}</p>
    <h3>{`鶏油(ちーゆ)の量を調整できる`}</h3>
    <BorderBox mdxType="BorderBox">
  <Text as="p" mdxType="Text">
    <Text as="span" fontWeight="bold" mdxType="Text">
      淡麗(たんれい):
    </Text>
    <Text as="span" fontWeight="bold" mdxType="Text">
      スタンダードな鶏油量で、きりっと澄んだスープの味をベストバランスで味わいたいときに。
    </Text>
    <Text as="span" fontWeight="bold" mdxType="Text">
      まろ味(まろあじ):
    </Text>
    <Text as="span" fontWeight="bold" mdxType="Text">
      淡麗のスープより、少し多めの鶏油量。らーめんのコクと香りを、より感じたいときに。
    </Text>
  </Text>
    </BorderBox>
    <p>{`出典：`}<a parentName="p" {...{
        "href": "https://afuri.com/menu/"
      }}>{`https://afuri.com/menu/`}</a></p>
    <h3>{`とりチャーシューに変更`}</h3>
    <p>{`炙りチャーシューを`}<strong parentName="p">{`とりチャーシュー`}</strong>{`に変更することができるので、脂っぽいものが苦手な方はこちらを選んで食べてみてください！`}</p>
    <InstaImage postId="Bgh2c5Yl4vh" include={[0]} mdxType="InstaImage" />
    <hr></hr>
    <h2>{`ラーメンはこだわりの食材を使用`}</h2>
    <h3>{`スープ`}</h3>
    <p>{`神奈川県丹沢山系の東端に位置する大山(阿夫利山)の麓から湧き出る清らかな天然水でスープを作ってそうです！阿夫利(afuri)という名前もここに由来して名付けられたそうです！`}</p>
    <InstaImage postId="B6zRhv5F869" mdxType="InstaImage" />
    <h3>{`麺`}</h3>
    <InstaImage postId="B7i9-tVAQhT" mdxType="InstaImage" />
    <ListBox list={["全粒粉入り小麦麺：北海道産の最高級全粒粉とライ麦を混ぜて作られている", "真空手揉み麺：真空ミキサーで製麺するため、余分な空気が入らずにモチモチした食感になるそう", "蒟蒻(こんにゃく)麺：食物繊維が豊富で低カロリーの麺"]} mdxType="ListBox" />
    <hr></hr>
    <h3>{`おわりに`}</h3>
    <p>{`阿夫利(afuri)についての概要と原宿店についてお伝えしてきましたが、いかがでしたでしょうか。
ぜひ、阿夫利(afuri)に訪れる際は、記事を参考にして素敵な食事にしてみてください！`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;