
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import PostLayout from "@src/layouts/PostLayout";
import { harajukuMokubazaTopImage } from "@src/constants/images";
import ShopInfoBox from "@src/components/ShopInfoBox";
import InstaImage from "@src/components/InstaImage";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import { murakamiMasanori } from "@src/constants/authors";
import { meta as mokubazaMenuPageMeta } from "./mokubaza_menu.mdx";
export const meta = {
  path: "/media/harajuku/mokubaza",
  title: "【原宿】カレーの行列店「mokubaza」の芸術的なキーマカレーの秘密",
  description: "ランチ激戦区の原宿で行列のできる人気店「mokubaza」のキーマカレーについてご紹介します。数十時間をかけて作る自家製のキーマカレーは、味だけでなくどんなメニューも目を奪われるほど美しい形。mokubazaに訪れる前に、こちらの記事でぜひチェックしてみてください！",
  topImg: harajukuMokubazaTopImage,
  author: murakamiMasanori,
  tags: ["原宿", "有名店", "人気店", "お店の紹介", "カレー", "食べログ高評価"]
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (
  <PostLayout meta={meta}>{children}</PostLayout>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ランチ激戦区の原宿で行列のできる人気店「mokubaza」のキーマカレーについてご紹介します。`}</p>
    <p>{`数十時間をかけて作る自家製のキーマカレーの濃縮された味、そしてトッピングしたどんなメニューも目を奪われるほど美しい姿をしたキーマカレーを厳選して写真付きでご紹介。`}</p>
    <p>{`mokubaza に訪れる際には、ぜひチェックしてみてください！`}</p>
    <hr></hr>
    <h2>{`mokubaza`}</h2>
    <p>{`原宿駅から表参道方面に徒歩 10 分ほど歩くと、ロッジハウス風のバーのような雰囲気のお店が見つかります。休日は連日行列が並び、芸能人もお忍びでくる人気のお店が mokubaza です。`}</p>
    <p>{`食べログの評価は`}<strong parentName="p">{`3.7`}</strong>{`を超え、2019 年には食べログ`}<strong parentName="p">{`カレー百名店`}</strong>{`に選出。名実共に実力派のカレー店です。
ランチだけでなく、夜は bar としても利用でき、カレーと一緒に 200 種類のお酒が楽しめるそうです。`}</p>
    <p>{`「夜に食べても胃もたれしないカレー」をコンセプトにしているため、夜にもたくさんのお客様が来客し、カレーを食べているんだそう。`}</p>
    <h3>{`ゆったり過ごせる店内`}</h3>
    <p>{`mokubaza の店内は、カウンター席が 5 席、テーブル席が 18 席の計 23 席用意されています。2 名のお客様には、広めの 4 人掛け席に通してくれるそうで、席を詰めたり相席にしたりせずに、十分に余裕がある空間でカレーを食べるられるのは嬉しいですよね！`}</p>
    <hr></hr>
    <h2>{`こだわりの調理法で作るキーマカレー`}</h2>
    <h3>{`添加物を使わないオリジナル`}</h3>
    <p>{`市販のルーやカレー粉も使用せず、厳選した食材とスパイスでつくるカレーは、他のお店には真似できない凝縮された旨味。小麦粉、化学調味料、人工添加物などを全く使用せず、 欧風でもインド風でもない、身体に優しい mokubaza のオリジナルです。`}</p>
    <h3>{`20 時間をかけて作るキーマカレー`}</h3>
    <InstaImage postId="CAtBo4LghKC" mdxType="InstaImage" />
    <p>{`mokubaza の全てのメニューのベースとなっているキーマカレーができるまでに、20 時間もの調理工程があるそうです。`}</p>
    <p>{`その 20 時間の工程では、国産の牛肉と豚肉を独自ブレンドで挽き肉にし、あめ色になるまで炒めた玉ねぎと混ぜ合わせます。`}</p>
    <p>{`そこに、 ココナッツミルクと 10 数種類のスパイスを、自家製のブイヨンでじっくり煮込んで、やっと完成するのがキーマカレー。`}</p>
    <p>{`ここまで時間をかけて作られたキーマカレーは、野菜が全てなくなり、挽肉に旨味が凝縮された状態になります。`}</p>
    <h2>{`mokubaza 人気のメニュー`}</h2>
    <h3>{`【キーマカレー本来の味】元祖キーマカレー`}</h3>
    <InstaImage postId="CAVR6migDM_" mdxType="InstaImage" />
    <p>{`先ほどお伝えした 20 時間の工程を経て作られたキーマカレーがこちらになります。凝縮された肉と野菜の旨みが口の中にスパイスと一緒に広がり、ジワジワと辛さが後から追いかけてくる。そんな風味です。`}</p>
    <p>{`卵がトッピングされており、卵黄を割ってカレーと一緒に食べれば、スパイスのピリッとした味わいをまろやかにしてくれます。`}</p>
    <p>{`mokubaza の本来の味を楽しみたい方は、元祖キーマカレーをご注文ください。`}</p>
    <hr></hr>
    <h3>{`【1 番人気】チーズキーマカレー`}</h3>
    <InstaImage postId="CAP1t59A_FK" mdxType="InstaImage" />
    <p>{`チーズキーマカレーは、mokubaza で 1 番人気のメニューです。元祖キーマカレーにたっぷりのモッツァレラチーズがトッピングされ、ドーム型になったキーマカレーは美しいの一言。その美しさに目を奪われ、思わずインスタグラムに写真を UP せずにはいられない一品です。`}</p>
    <p>{`たっぷりのモッツァレラチーズと卵が、スパイスの効いたキーマカレーの味をまろやかに包み込んでくれる、優しい味の一品です。`}</p>
    <hr></hr>
    <h2>{`【インスタ映え】アボカドチーズキーマカレー`}</h2>
    <InstaImage postId="B_MNG1eB9GH" mdxType="InstaImage" />
    <p>{`同じサイズで丁寧にカットされた完熟アボカドが、キーマカレーの上に均等な感覚で配置されているのを、たっぷりトッピングされたモッツァレラチーズの上から確認することができます。`}</p>
    <p>{`この美しい mokubaza の人気カレーはインスタ映え間違いなしの一品で、インスタにたくさんの写真が UP されています。`}</p>
    <p>{`一度でたくさんの味を楽しめることでもポイントで、真ん中の卵黄とチーズを割ると、スパイスの効いたキーマカレーとアボカドが登場し、スパイス、チーズ、アボカド、卵黄が口の中で踊り出すような感覚。`}</p>
    <hr></hr>
    <h2>{`【一味違うカレーを】焼きアボカドキーマカレー`}</h2>
    <InstaImage postId="BH6QE8kBXwM" mdxType="InstaImage" />
    <p>{`アボカドチーズキーマカレーをオーブンでじっくり焼いたカレーが、`}<strong parentName="p">{`焼きアボカドキーマカレー`}</strong>{`です！通常のアボカドチーズキーマカレーに熱が加わることで、スパイスの風味が変化しいつもの mokubaza のカレーとは少し違った風味を味わえます。`}</p>
    <p>{`こんがり焼けたチーズの外はカリッと、中はとろとろのカレースプーンですくえばとろとろの食感を二度楽しめる一品です！`}</p>
    <hr></hr>
    <h2>{`店舗情報`}</h2>
    <ShopInfoBox shopId="br7847a23akg00ac68hg" mdxType="ShopInfoBox" />
    <LinkWithThumbnail meta={mokubazaMenuPageMeta} mdxType="LinkWithThumbnail" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;